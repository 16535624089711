<template>
  <div class="search container">
    <a-spin :spinning="isLoading" size="large">
      <a-breadcrumb separator="" class="page-breadcrumb">
        <a-breadcrumb-item>
          <router-link to="/mycards" v-if="iccid">
            {{ $t('route.my_cards') }}
          </router-link>
          <router-link to="/" v-else>
            {{ $t('route.home') }}
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-separator>
          <a-icon type="right" />
        </a-breadcrumb-separator>
        <a-breadcrumb-item>
          <router-link :to="`/topup?iccid=${iccid}`" v-if="iccid">
            {{ $t('route.topup') }}
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-separator v-if="iccid">
          <a-icon type="right" />
        </a-breadcrumb-separator>
        <a-breadcrumb-item>{{ $t('route.search') }}</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="section-title" >
        <div>{{ iccid ? $t('route.topup') : $t('route.home')}}</div>
        <div class="section-subtitle" >{{iccid ? $t('common.title_10') :$t('common.slogan')}}</div>
      </div>
      <topup-search :iccid="iccid" :keyword="keyword" v-if="iccid"/>
      <div class="keywords">"{{ keyword }}"</div>
      <div class="section-products" v-show="!isEmpty">
        <a-row :gutter="[{ xs: 14, sm: 20, xxl: 30 }, { xs: 14, sm: 20, xxl: 30 }]" class="products">
          <a-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6" v-for="item in searchResults" :key="item.district_id">
            <div class="product-item" @click="handleBuyNow(item)" :title="item.name || item.district_name">
              <div class="product-cover">
                <img class="product-img" :src="item.cover" :alt="item.name || item.district_name"
                     :title="item.name || item.district_name" />
              </div>
              <div class="product-info">
                <div class="product-name text-overflow-example">{{ item.name || item.district_name }}</div>
                <div class="product-lowest-price">
                  <span class="product-price-from">{{ $t("common.from") }}</span>
                  <span>{{ item.lowest_price }}</span>
                </div>
                <div class="product-icon flex">
                  <a-icon type="right" />
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-show="isEmpty" class="empty-wrapper flex">
        <a-empty image="https://img.triproaming.cn/weapp/search_none.png">
          <span slot="description" class="empty-text"> {{ keyword ? $t('common.search_3') : $t('common.search_4') }} </span>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>
<script>
import TopupSearch from '../components/TopupSearch'
export default {
  name: 'Search',
  components: {
    TopupSearch,
  },
  data() {
    return {
      iccid: this.$route.query.iccid,
      keyword: this.$route.query.keyword,
      searchResults: [],
      isEmpty: false,
      isLoading: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    handleBuyNow(item) {
      const { district_id:id,district_name:name } = item
      const query = { id, ...(this.iccid&&{iccid:this.iccid}) }
      this.$router.push({ name: 'Details', query });
      window._hmt.push(['_trackEvent', 'button', 'click', `浏览套餐-${name}`]);
    },
    async init() {
      const { keyword, iccid } = this.$route.query
      if (!keyword.replace(/\s/g, '')) return this.searchResults = []
      try {
        this.isLoading = true
        const params = {
          words: keyword,
          ...(iccid && { iccid })
        }
        const { data } = await this.$http.get(this.APIURL.HOME.SEARCH, params, false)
        this.searchResults = data
        this.isEmpty = data <= 0
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.isEmpty = data <= 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search {
  cursor: pointer;
  min-height: 80vh;

  .keywords {
    padding: 0 10px;
    font-size: 20px;
    font-weight: 600;

    @include respond-to('phone') {
      font-size: 14px;
    }
  }
}
</style>